.deniedText, .closeBrowser {
  margin-top: 10px;
}

.closeBrowser {
  font-weight: bold;
}

.warningIcon {
  color: #C10015;
  margin-top: 3px;
  margin-right: 5px;
}
