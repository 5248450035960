.root {
    margin-right: 5px;
    margin-left: 0;
    line-height: 1.43;
}
.flex-start {
    align-items: 'flex-start';
}
.flex-end {
    align-items: 'flex-end';
}
.center {
    align-items: 'center';
}
