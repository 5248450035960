$YELLOW: #ffea77;
$DARK_YELLOW: #F8E34D;
$LIGHT_YELLOW: #FFF5D6;
$DARKEST_YELLOW: #f7db43;
$BLACK: #141a18;
$DARK_BLUE: #106c92;
$DARKEST_BLUE: #1a5f83;
$MIDNIGHT_BLUE: #13142a;
$SOME_KIND_OF_BLUE: #003873;
$EVIL_BLUE: #293e64;
$PALE_BLUE: #e3eff7;
$ICY_BLUE: #99B1C7;
$MIDDLE_BLUE: #1279a3;
$LIGHT_BLUE: #277a9b;
$BABY_BLUE: #cde6f7;
$SKY_BLUE: #4b8aff;
$GREY_BLUE: #aecdda;
$GARGOYLE_GREY: #d5d6d9;
$LIGHTER_GREY: #F7FAFB;
$WHITE: #ffffff;
$GREY: #535862;
$SUPER_LIGHT_GREY: #ebebeb;
$LIGHT_GREY: #838383;
$WHITE_GREY: #f2f2f2;
$DARK_GREY: #3a3b40;
$ORANGE: #ff8617;
$TURQUOISE: #2dbfc7;