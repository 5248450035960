@use '../theme.scss';

/* switch styles */

.root {
    width: 30px;
    height: 18px;
    padding: 0;
    margin-right: 12px;
    // overflow: 'visible' is needed because otherwise the thumb's shadow is cut off
    overflow: visible;
}

.switchBase {
    padding: 1px 2px;

    &:hover {
        background-color: theme.$TRANSPARENT; // Removes thumb glow on hover
    }

    &:hover + .track {
        background-color: theme.$BABY_BLUE;
    }

    &.checked {
        transform: translateX(10px);
    }
}

.colorSecondary {
    &.checked:hover + .track {
        background-color: theme.$DARK_BLUE;
    }
    &.checked + .track {
        background-color: rgba(theme.$DARK_BLUE, 0.68);
    }
}

.thumb {
    width: 16px;
    height: 16px;

    background-color: theme.$WHITE;
    box-shadow: 0 2px 3px 0 rgba(theme.$EVIL_BLUE, 0.34);
}

.track {
    opacity: 1;
    border-radius: 10px;
    background-color: theme.$PALE_BLUE;
}
