// I want this to mimic theme.spacing(1), etc.
$spacing-base: 5px;

// The spacing method can be called with between 1 and 4 params, i.e.
//   - padding-bottom: theme.spacing(2);
//   - margin: theme.spacing(3, 3, 3);
//   - padding: theme.spacing(3, 9, 4, 4);
@function spacing($a, $b: null, $c: null, $d: null) {
    @if ($d) {
        @return ($spacing-base * $a) ($spacing-base * $b) ($spacing-base * $c)
            ($spacing-base * $d);
    } @else if ($c) {
        @return ($spacing-base * $a) ($spacing-base * $b) ($spacing-base * $c);
    } @else if ($b) {
        @return ($spacing-base * $a) ($spacing-base * $b);
    } @else {
        @return $spacing-base * $a;
    }
}

$YELLOW: #ffea77;
$DARK_YELLOW: #f8e34d;
$LIGHT_YELLOW: #fff5d6;
$DARKEST_YELLOW: #f7db43;
$BLACK: #141a18;
$DARK_BLUE: #106c92;
$DARKEST_BLUE: #1a5f83;
$MIDNIGHT_BLUE: #13142a;
$SOME_KIND_OF_BLUE: #003873;
$EVIL_BLUE: #293e64;
$PALE_BLUE: #e3eff7;
$ICY_BLUE: #99b1c7;
$MIDDLE_BLUE: #1279a3;
$LIGHT_BLUE: #277a9b;
$BABY_BLUE: #cde6f7;
$SKY_BLUE: #4b8aff;
$GREY_BLUE: #aecdda;
$GREY_BLUE_WASHED_OUT: #f2fafd;
$LIGHT_RED: #ff0000;
$DARK_RED: #0c0b0b;
$DARKEST_RED: #831a1a;
$GARGOYLE_GREY: #d5d6d9;
$LIGHTER_GREY: #f7fafb;
$WHITE: #fff;
$GREY: #535862;
$SUPER_LIGHT_GREY: #ebebeb;
$LIGHT_GREY: #838383;
$WHITE_GREY: #f2f2f2;
$DARKISH_GREY: #6c6c84;
$DARK_GREY: #3a3b40;
$ORANGE: #ff8617;
$TURQUOISE: #2dbfc7;
$TRANSPARENT: transparent;
$BROWN: #806363;
$GREEN: #14a955;
